import React from 'react'
import IconPartAndTube from '../../components/svg/IconPartAndTube'
import PageHeader from '../../components/common/PageHeader'
import IconCorporateOffice from '../../components/svg/IconCorporateOffice'
import IconWarehouse from '../../components/svg/IconWarehouse'
import { Button } from '../../components/ui/Button'

const ContactUs = () => {
  return (
    <section className='what-we-do'>
      <PageHeader title="Contact Us" subNav="Contact Us" />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="lg:py-12 sm:py-8 py-6">
            <div className="grid lg:grid-cols-2 sm:gap-6 gap-4">
              <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
                <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square">
                  <IconCorporateOffice className="lg:w-20 w-16 bg-white p-2 shadow-md" />
                </div>
                <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>CORPORATE OFFICE</h2>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>101, 1st Floor, Tirth Complex , Beside Anupam Square, Near Mahadev Chowk, Mota Varaccha, Surat- 394101, Gujarat, INDIA.
                </h5>
              </div>
              <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="zoom-in" data-aos-duration="2000">
                <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square">
                  <IconPartAndTube className="lg:w-20 w-16 bg-white p-2 shadow-md" />
                </div>
                <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>EMAIL & PHONE NO.</h2>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>suraj@connexindustrialsolutions.com </h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>7039332456</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-10'>
        <div className="container">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Get In Touch With Us</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="form-wrapper max-w-[800px] mx-auto">
            <form className='flex flex-col sm:gap-6 gap-4'>
              <div className="grid sm:grid-cols-2 w-full gap-4">
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="fName" className='mb-1'>First Name</label>
                  <input type="text" id='fName' placeholder='John' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
                <div className="form-group flex flex-col w-full">
                  <label htmlFor="lName" className='mb-1'>Last Name</label>
                  <input type="text" id='lName' placeholder='Doe' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
                </div>
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="subject" className='mb-1'>Subject</label>
                <input type="text" id='subject' placeholder='e.g.' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="email" className='mb-1'>Email adress</label>
                <input type="text" id='email' placeholder='abc@gmail.com' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="phone" className='mb-1'>Phone No.</label>
                <input type="number" id='phone' placeholder='9980998020' className='w-full border-2 outline-none border-gray-400 p-2 px-4' />
              </div>
              <div className="form-group flex flex-col w-full">
                <label htmlFor="phone" className='mb-1'>Message</label>
                <textarea id="phone" rows="4" className='w-full border-2 outline-none border-gray-400 p-2 px-4'></textarea>
              </div>
              <Button className="w-max px-8">Submit</Button>
            </form>
          </div>
          <div className="map w-full pt-6">
            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.8855948626256!2d72.88161347503745!3d21.2363847804654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f391f0ceca9%3A0xa5b74d8f54164e81!2sConnex%20Industrial%20Solutions%20LLP!5e0!3m2!1sen!2sin!4v1714826926601!5m2!1sen!2sin" width="100%" height="350px" frameborder="0" allowfullscreen=""></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
