import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/css/App.css"
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Product from "./pages/Product/Product";
import WhyConnex from "./pages/WhyConnnex/WhyConnex";
import ContactUs from "./pages/ContactUs/ContactUs";
import Analytical from "./pages/Product/Analytical";
import Industry from "./pages/Product/Industry";
import CorporateVideos from "./pages/About/CorporateVideos";
import Expertise from "./pages/Expertise/Expertise";
import Career from "./pages/Career/Career";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/analytical" element={<Analytical />} />
          <Route path="/industry" element={<Industry />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/why-us" element={<WhyConnex />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/corporate" element={<CorporateVideos />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
