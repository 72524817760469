import React from 'react'
import IconVision from '../../components/svg/IconVision'
import IconMission from '../../components/svg/IconMission'
import IconValue from '../../components/svg/IconValue'
import PageHeader from '../../components/common/PageHeader'

const About = () => {
  return (
    <div className='about-section'>
      <PageHeader title="About Us" subNav="About Us" />
      <div className="container">
        <div className='flex flex-col lg:py-16 py-10 md:max-w-[80%] mx-auto'>
          <h1 className='lg:text-4xl md:text-3xl text-2xl text-center text-gray-600 font-600 lg:mb-8 mb-6'>About Connex Industrial Solutions LLP</h1>
          <div data-aos="fade-right" data-aos-duration="1000">
            <p className='lg:text-lg text-gray-600 font-500 leading-[22px] lg:mb-6 mb-4'>Connex is a premier electronics repair service provider. We stared our journey since 2022. We offer our customers the assuarance of a quality repair job in a timely and cost effective manner. Our repair expertise in healthcare, anaytical and industry sectors.
            </p>
            <p className='lg:text-lg text-gray-600 font-500 leading-[22px]'>We have the capability to repair the printed circuit board (PCB) on component level. Using state of the art equipment, we are able to troubleshoot problems down to the component level, which often enables us to provide repairs at a cost lower than those of replacement parts. Connex offers a single window approach to warranty management by taking care of reverse logistics for Do as and warranty failures. Connex specilizes in repair, BGA rework, test and inspction of electronic circuit Boards and assembles with automatic test equipment (IC in Circuit Tester) and Complete lab facility and ROHS compliant components for pcb repairing process. We provide level 4 repair of any complex electronic circuit boards.
            </p>
          </div>
        </div>
        <div className="lg:py-12 sm:py-8 py-6">
          <div className="flex justify-center items-center gap-6 sm:mb-10 mb-6">
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap'>Guiding Principles</h2>
            <div className='sm:w-[100px] h-0.5 bg-theme2'></div>
          </div>
          <div className="grid lg:grid-cols-2 sm:gap-6 gap-4 bg-theme2/20 p-8">
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconVision className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Vision</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>Our Vision is Growth, Innovation and Reach; Growth through Innovation in products, business models, and center of excellence in service support and Reach every town, hospitals across India including smaller and rural places.</h5>
            </div>
            <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconMission className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Mission Statement</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We believe in treating our customers with respect and faith. We grow through creativity, invention and innovation. We integrate honesty, integrity and business ethics into all aspects of our business.
              </h5>
            </div>
            {/* <div className="card w-full bg-white rounded-lg sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" data-aos="fade-left" data-aos-duration="1000">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full">
                <IconValue className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>Core Values</h2>
              <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center'>We sell a range parts from a number of different brands for a large variety of Medical Imaging Equipment. The State of the art warehouse, having 2 Lakh plus parts to serve you. Our warehouses works for 24 X 7.</h5>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
