import React from 'react'

const IconMission = (props) => {
  return (
    <svg {...props} viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clippPath="url(#clip0_53228_5935)">
        <path d="M72.971 72.9805C88.9971 56.9544 88.9971 30.9709 72.971 14.9448C56.9449 -1.08129 30.9614 -1.08129 14.9353 14.9448C-1.09079 30.9709 -1.09079 56.9544 14.9353 72.9805C30.9614 89.0066 56.9449 89.0066 72.971 72.9805Z" fill="#FF6C6C" />
        <path d="M43.9624 2.92529V85C66.5905 85 84.9998 66.5906 84.9998 43.9625C84.9998 21.3344 66.5905 2.92529 43.9624 2.92529Z" fill="#E63950" />
        <path d="M48.4536 70.874C63.3087 68.3881 73.336 54.3304 70.8501 39.4751C68.3641 24.6199 54.3064 14.5926 39.4513 17.0785C24.5962 19.5644 14.5689 33.6221 17.0549 48.4774C19.5408 63.3326 33.5985 73.3599 48.4536 70.874Z" fill="#F0F7FF" />
        <path d="M43.9624 16.6909V71.2343C59 71.2343 71.2342 59.0002 71.2342 43.9625C71.2342 28.9251 59 16.6909 43.9624 16.6909Z" fill="#DFE7F4" />
        <path d="M56.0012 50.1089C59.3876 43.4627 56.745 35.3298 50.0988 31.9434C43.4527 28.557 35.3197 31.1996 31.9333 37.8457C28.547 44.4919 31.1895 52.6248 37.8357 56.0112C44.4818 59.3976 52.6148 56.755 56.0012 50.1089Z" fill="#FF6C6C" />
        <path d="M43.9624 30.4568V57.4687C51.4097 57.4687 57.4685 51.4101 57.4685 43.9628C57.4685 36.5154 51.4097 30.4568 43.9624 30.4568Z" fill="#E63950" />
        <path d="M30.3967 12.7887L18.3384 0.730468C18.0432 0.435032 17.6784 0.218515 17.2777 0.100804C16.8769 -0.0169082 16.453 -0.032042 16.0449 0.0567921C15.6368 0.145626 15.2575 0.33558 14.9419 0.60921C14.6264 0.882839 14.3846 1.23137 14.2388 1.6228L11.7708 8.24463L12.4758 12.4759L8.24453 11.7709L1.62286 14.2388C1.23158 14.3847 0.883216 14.6266 0.609721 14.9421C0.336226 15.2577 0.146361 15.6369 0.0575435 16.045C-0.0312736 16.453 -0.0161979 16.8768 0.101387 17.2776C0.218973 17.6783 0.435302 18.043 0.730529 18.3384L12.7888 30.3966C13.0736 30.6816 13.4233 30.8933 13.8078 31.0135C14.1923 31.1338 14.6003 31.1591 14.9967 31.0872L26.9173 28.9262C27.4168 28.8355 27.8767 28.5947 28.2356 28.2357C28.5946 27.8768 28.8354 27.4169 28.9261 26.9174L31.0871 14.9968C31.1589 14.6004 31.1336 14.1924 31.0134 13.8079C30.8932 13.4233 30.6816 13.0736 30.3967 12.7887Z" fill="#29376D" />
        <path d="M45.7254 42.1994L11.7704 8.24463L10.8131 10.8136L8.24414 11.7709L42.1991 45.7259C42.4304 45.9577 42.7052 46.1416 43.0077 46.2669C43.3103 46.3922 43.6347 46.4565 43.9621 46.4561C44.6003 46.4561 45.2385 46.2126 45.7252 45.7259C46.6991 44.7521 46.6991 43.1732 45.7254 42.1994Z" fill="#47568C" />
      </g>
      <defs>
        <clipPath id="clip0_53228_5935">
          <rect width="85" height="85" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconMission
