import img1 from "./assets/images/PATIENT MONITOR.webp"
import img2 from "./assets/images/SYRINGE PUMPS.jpg"
import img3 from "./assets/images/CAUTERY.png"
import img4 from "./assets/images/VENTILATOR.gif"
import img5 from "./assets/images/ULTRASOUND.png"
import img6 from "./assets/images/CR SYSTEMS.webp"
import img7 from "./assets/images/CT.png"
import img8 from "./assets/images/WORKSTATIONS.jpg"
import img9 from "./assets/images/medrad_injector.jpg"
import img10 from "./assets/images/LAPAOSCOPY CAMERA.webp"

export const repairData = [
  {
    id: 1, title: 'Patient Monitors', data: "SUCCESSFULLY REPAIRING ALL BRANDS OF PATIENT MONITORS SUCH AS",
    listData: ["GE", "PHILIPS", "HAMILTON", "NIHON KOHDEN", "CHINESE MODELS"],
    img: img1
  },
  { id: 2, title: 'Syringe Pumps', data: "SUCCESSFULLY REPAIRING ALL BRANDS OF SYRINGE PUMPS SUCH AS", listData: ["FRESENIUS KABI & CHINESE MODELS"], img: img2 },
  { id: 3, title: 'Cautery', data: "SUCCESSFULLY REPAIRING CAUTERIES OF", listData: ["FORCE FX", "VALLYLAB", "SHALYA & OTHER MODELS"], img: img3 },
  { id: 4, title: 'Ventilators', data: "SUCCESSFULLY REPAIRING", listData: ["DRAEGER & SCHILLER BRANDS EVITA XL", "EVITA 2 DURA", "EVITA", "GRAPHNET", "GRAPHNET TS", "AQVILON TS", "SOPHIE AND OTHER MODELS"], img: img4 },
  { id: 5, title: 'Ultrasound', data: "SUCCESSFULLY REPAIRING GE, PHILIPS,ALOKA, SIEMENS ULTRASOUND MODELS SUCH AS", listData: ["VOLUSON P8, S6, S7, C5 PREMIUM", "ACUSON X300,X700", "ALPHA 6", "LOGIQ P5, P6", "HD7, HD11, HD15 ETC & OTHER MODELS"], img: img5 },
  { id: 6, title: 'CR Systems', data: "SUCCESSFULLY REPAIRING FUJIFILM ALL READERS & PRINTERS SUCH AS", listData: ["DRYPIX 7000", "DRYPIX LITE", "PRIMA T", "PRIMA T2", "CAPSULA", "CAPUSLA XL ETC & OTHER MODELS"], img: img6 },
  { id: 7, title: 'CT/MR', data: "SUCCESSFULLY REPAIRING GE, TOSHIBA, SIEMENS CT/ MR MODELS SUCH AS", listData: ["BRIGHTSPEED", "LIGHTSPEED", "AQUILON 16", "SUPER FOUR"], img: img7 },
  {
    id: 8, title: 'Work stations', data: "SUCCESFULLY REPAIRING WORKSTATIONS BRANDS SUCH AS SIEMENS, FUJITSU, HP, SUN & THEIR MODELS SUCH AS", listData: ["IRS, IVS, IAS", "CELSIUS", "Z400, Z600", "SUNBALDE 2500"], img: img8
  },
  { id: 9, title: 'Pressure Injector', data: "SUCCESSFULLY REPAIRING ALL BRANDS SINGLE AND DUAL HEAD INJECTORS SUCH AS", listData: ["NEMOTO", "MEDRAD", "BAYER", "SINO POWER"], img: img9 },
  { id: 10, title: 'Laparoscopy camera', data: "SUCCESSFULLY REPAIRING BRAND STRYKER, KARLSTORZ, OLYMPUS, SMITH & NEPHEW, RICHARD WOLF & ALL THEIR MODELS SUCH AS", listData: ["1188", "1288", "1488", "1588", "IMAGE 1 HUB", "TRICAM", "DX PAL", "PAL"], img: img10 },
]

export const serviceData = [
  {
    tabName: "Preventive",
    title: "Preventive Maintenance Contract",
    img: "preventive.jpg",
    description: [
      "Argon provides Preventive maintenance agreement to its customer. The package cost is included for service charges only for a desired period. The cost of spares replaced is payable by Customer."
    ],
    benifit: {
      title: "Preventive maintenance services at fixed expense, including",
      benifits: [
        "Checking and recalibration of machine in a year",
        "Attending all breakdown at no extra cost",
        "Two preventive maintenance inspections during the twelve month agreement period.",
        "No charges for travel and visit charge",
        "Reduced radiation dose to patient and operator",
        "Minimum Repair Time"
      ]
    }
  },
  {
    tabName: "Comprehensive",
    title: "Comprehensive Maintenance Contract",
    img: "comprehensive.jpg",
    description: [
      "With Argon Medical Systems Comprehensive Maintenance Agreement you’ll receive the highest quality service possible, With 10 years of the experience from the oldest to the newest CT scanner our engineers are factory-trained and certified.",
      "Argon provides all travel, labor and parts for your system, as well as three preventative maintenance inspections during a twelve month period. Our goal is to respond to all service calls within eight working hours. In addition, our technical support department provides phone support at no additional charge."
    ],
  },
  {
    tabName: "Equipment Relocation",
    title: "Equipment Relocation & Installation",
    img: "relocation.jpg",
    description: [
      "We can move your Precious CT Scanner system in less time and with less cost. With our experienced and trained installation team, and having the right tools and equipment, we know the intricacies of a safe and fast relocation of your assets for more productive use. Our installations and relocations are done entirely by Argon Medical Systems highly experienced service engineer’s team led by a Project Manager and supported by Modality Operations, Service Managers. This support ensures visibility to your needs as a customer from all areas of Argon Medical Systems."
    ],
  },
]