import React from 'react'

const Welcome = () => {
  return (
    <>
      <section className='welcome-section bg-[url("../../../src/assets/images/sticky.jpg")] sm:bg-fixed bg-cover bg-no-repeat sm:max-sm:bg-[center_top_0rem] bg-center sm:bg-black/80 bg-black/85 bg-blend-overlay lg:py-40 py-20 max-w-[1640px] mx-auto'>
        <div className="container h-full">
          <div className="grid items-center justify-center h-full">
            <div className="content text-center" data-aos="fade-right" data-aos-duration="1500">
              <h2 className='lg:text-[20px] sm:text-lg text-center font-500 lg:mb-12 mb-8 text-white flex flex-col' >WELCOME <span className='sm:text-lg font-600'>to</span> <span className='lg:text-4xl sm:text-2xl text-xl font-600 text-[#ffdd9a]'>CONNEX INDUSTRIAL SOLUTIONS LLP</span></h2>
              <h5 className='lg:text-lg sm:text-base text-sm text-white lg:mb-6 mb-5 lg:max-w-[80%] mx-auto'>"We are offering exceptional assortment of Healthcare, Analytical and Industrial PCB Repairing services to our customers.Our team is make sure that the PCB Repairing services offered by us are followed the Quality and Testing norms. Also we are offered PCB repairing services are respected for their Reasonable Prices, Perfect Execution and Customer-centric approach.
              </h5>
              <h5 className='lg:text-lg sm:text-base text-sm text-white lg:mb-6 mb-5 lg:max-w-[80%] mx-auto'>A Comprehensive Solution for Healthcare, Analytical & Industrial Equipment Repairs on component level. Connex is a leading provider of repair and service solutions for Healthcare, Analytical & Industrial Equipment.
                With a focus on quality and efficiency, the company offers a wide range of repair services for Healthcare Equipment Patient monitors, Syringe Pumps, Cautery, Ventilators, Ultrasound, CR System, Laparoscopy camera, CT/MRI, Workstations, Pressure Injector.</h5>
              <h5 className='lg:text-lg sm:text-base text-sm text-white lg:mb-6 mb-5 lg:max-w-[80%] mx-auto'>In Analytical, We repairs TOC Analyzers, Air sampler, Balances, HPLC, G.C, Incubators, Shakers, Stirrers Etc.
                In Industrial Division, We repairs various Top Brands AC/DC Drives, PLCs, HMIs, Servo Motors Etc."</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Welcome
