import React from 'react'
import { Link } from 'react-router-dom'
import IconLocation from '../svg/IconLocation'
import IconCall from '../svg/IconCall'
import IconMail from '../svg/IconMail'
import IconSend from '../svg/IconSend'

const Footer = () => {
  return (
    <>
      <section className='py-10 bg-theme2'>
        <div className="container">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 items-center sm:gap-8 gap-6">
            <div className="newsletter max-lg:col-span-2 max-md:col-span-1">
              <div className="navigation mb-8">
                <ul className='flex flex-col gap-2'>
                  <li>
                    <Link to={"/"} className='text-white font-poppins font-500 sm:text-base text-sm pb-1'>> Home</Link>
                  </li>
                  <li>
                    <Link to={"/"} className='text-white font-poppins font-500 sm:text-base text-sm pb-1'>> About Us</Link>
                  </li>
                  <li>
                    <Link to={"/"} className='text-white font-poppins font-500 sm:text-base text-sm pb-1'>> Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="flex mb-4">
                <input type="text" className='p-2 w-full sm:text-base rounded-s-md text-sm max-w-[300px]' placeholder='Enter your email address...' />
                <div className="icon w-10 border-s border-gray-400 flex justify-center rounded-e-md items-center bg-orange-500"><IconSend className="w-5 text-white font-poppins font-500" /></div>
              </div>
            </div>
            <div className="flex flex-col sm:gap-4 gap-2.5">
              <div className="flex gap-2.5 items-start">
                <IconLocation className="w-6 flex-shrink-0 text-white font-poppins font-500" />
                <h2 className='xl:text-base text-sm text-white font-poppins font-500'>101,1st Floor Tirth Complex,beside Anupam Square, Near Mahadev Chowk, Mota Varachha, Surat-394101,Gujarat,India</h2>
              </div>
              <div className="flex gap-2.5 items-start">
                <IconCall className="w-6 flex-shrink-0 text-white font-poppins font-500" />
                <h2 className='xl:text-base text-sm text-white font-poppins font-500'>7039332456</h2>
              </div>
              <div className="flex gap-2.5 items-start">
                <IconMail className="w-6 flex-shrink-0 text-white font-poppins font-500" />
                <h2 className='xl:text-base text-sm text-white font-poppins font-500'>suraj@connexindustrialsolutions.com</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright py-2 bg-gray-800 text-white font-poppins font-500 text-center sm:text-sm xsm:text-xs text-[10px]">
        Copyright © 2022 Connex Industrial Solutions LLP. All right reserved.
      </div>
    </>
  )
}

export default Footer
