import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { repairData } from '../../data'
import IconClose from '../svg/IconClose'
import IconMenu from '../svg/IconMenu'
import IconRight from '../svg/IconRight'
import Backdrop from './Backdrop'

const Header = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [widthSize, setWidthSize] = useState(0);
  const [openNav, setOpenNav] = useState("");
  const [openSubNav, setOpenSubNav] = useState({ mainid: null, subid: null });
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname)

  const handleNavigate = (url, data) => {
    navigate(url, { state: data })
  }

  let resizeWindow = () => {
    setWidthSize(window.innerWidth);
  };

  const handleClick = (id) => {
    if (id === openNav) {
      setOpenNav("")
    } else {
      setOpenNav(id)
    }
  }

  const handleSubNavClick = (e, obj) => {
    e.stopPropagation()
    if (obj.subid === openSubNav.subid) {
      setOpenSubNav({ mainid: null, subid: null })
    } else {
      setOpenSubNav({ mainid: obj.mainid, subid: obj.subid })
    }
  }

  useEffect(() => {
    setToggleSidebar(false)
  }, [location.pathname])

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  let mobileClass = `fixed top-0 ${toggleSidebar ? "start-0" : "-start-full"} duration-300 bg-theme h-screen w-full max-w-[280px] transition-all ease-in z-10 py-8 px-5 xl:p-0 overflow-y-auto`;

  return (
    <header className='shadow-header fixed top-0 right-0 left-0 w-full z-50 bg-gray-100 shadow-md'>
      {
        toggleSidebar && <Backdrop className={""} onClick={() => setToggleSidebar(false)} />
      }
      <div className="container">
        <div className="flex items-center py-2">
          <div className="header-left w-full flex items-center justify-between">
            <div className="site-logo">
              <Link to="/">
                <div className='xl:w-[200px] w-[150px]'>
                  <img src={require("../../assets/images/logo.png")} alt="" className='mix-blend-multiply' />
                </div>
              </Link>
            </div>
            <nav className={`${widthSize < 992 ? mobileClass : "max-lg:hidden"}`}>
              <ul className="flex max-lg:flex-col lg:items-center xl:gap-6 gap-2">
                <li>
                  <Link to="/" >
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>Home</h4>
                  </Link>
                </li>
                <li>
                  <Link to="about">
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>About Us</h4>
                  </Link>
                </li>
                <li>
                  <Link to="/why-us">
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>Why Connex</h4>
                  </Link>
                </li>
                <li className='group relative max-lg:hidden'>
                  <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate cursor-pointer'>
                    Repairs
                  </h4>
                  <ul className="lg:w-[220px] w-full bg-white mt-1 absolute z-10 top-[calc(100%+10px)] invisible group-hover:visible group-hover:top-[calc(100%)] transition-all duration-700">
                    <div className="shadow-md">
                      <li className='group/submenu border-b-2'>
                        <div className='flex justify-between p-4 py-2 relative before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>
                          <h4>Healthcare</h4>
                          {/* <IconRight className="w-3.5" /> */}
                        </div>
                        <ul className="w-[220px] lg:absolute bg-white right-full top-0 invisible group-hover/submenu:visible group-hover:top-0 transition-all duration-700">
                          <div className="shadow-md">
                            {
                              repairData.map((data) =>
                                <li key={data.id}>
                                  <div onClick={() => handleNavigate(`/product/${data.id}`, data)}>
                                    <h4 className='p-4 py-2 border-b-2 relative before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 cursor-pointer'>{data.title}</h4>
                                  </div>
                                </li>)
                            }
                          </div>
                        </ul>
                      </li>
                      <li>
                        <Link to="/analytical">
                          <h4 className='p-4 py-2 relative border-b-2 before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Analytical</h4>
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry">
                          <h4 className='p-4 py-2 relative border-b-2 before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Industry</h4>
                        </Link>
                      </li>
                      <li>
                        <Link to="/expertise">
                          <h4 className='p-4 py-2 relative border-b-2 before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Our Expertise In</h4>
                        </Link>
                      </li>
                    </div>
                  </ul>
                </li>

                {/* mobile nav start */}
                <li className='relative lg:hidden' onClick={() => handleClick("Repairs")}>
                  <div className="flex justify-between p-2.5 py-2 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 before:bg-theme2 before:top-full before:left-1/2">
                    <h4>
                      Repairs
                    </h4>
                    <IconRight className={`w-3.5 ${openNav === "Repairs" ? "-rotate-90" : "rotate-90"}`} />
                  </div>
                  <ul className={`lg:w-[220px] w-full ${openNav === "Repairs" ? "h-full" : "h-0 overflow-hidden"}`}>
                    <div className="">
                      <li onClick={(e) => handleSubNavClick(e, { mainid: "Repairs", subid: "scan" })}>
                        <div className='flex justify-between p-2.5 py-2 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2' >
                          <h4>Healthcare</h4>
                          <IconRight className={`w-3.5 ${openSubNav?.mainid === "Repairs" && openSubNav?.subid === "scan" ? "-rotate-90" : "rotate-90"}`} />
                        </div>
                        <ul className={`lg:w-[220px] ${openSubNav?.mainid === "Repairs" && openSubNav?.subid === "scan" ? "h-full" : "h-0 overflow-hidden"} shadow-lg top-[40px] lg:pt-5`}>
                          {
                            repairData.map((data) =>
                              <li key={data.id}>
                                <div onClick={() => handleNavigate(`/product/${data.id}`, data)}>
                                  <h4 className='p-4 py-2 max-lg:text-white relative before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>{data.title}</h4>
                                </div>
                              </li>)
                          }
                        </ul>
                      </li>
                      <li>
                        <Link to="/analytical">
                          <h4 className='p-4 py-2 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Analytical</h4>
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry">
                          <h4 className='p-4 py-2 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Industry</h4>
                        </Link>
                      </li>
                      <li>
                        <Link to="/expertise">
                          <h4 className='p-4 py-2 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2'>Our Expertise In</h4>
                        </Link>
                      </li>
                    </div>
                  </ul>
                </li>
                {/* mobile nav end */}
                <li>
                  <Link to="/contact-us">
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>Contact Us</h4>
                  </Link>
                </li>
                <li>
                  <Link to="/career">
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>Career</h4>
                  </Link>
                </li>
                <li>
                  <Link to="/corporate">
                    <h4 className='px-2.5 py-2 xl:text-lg font-500 text-gray-800 relative max-lg:text-white before:absolute before:w-0 hover:before:w-full hover:before:left-0 before:transition-all before:duration-300 before:h-1 lg:before:bg-theme before:bg-theme2 before:top-full before:left-1/2 truncate'>Corporate</h4>
                  </Link>
                </li>
              </ul>
              <div className="lg:hidden close-menu absolute top-4 right-4 cursor-pointer" onClick={() => setToggleSidebar(false)}>
                <IconClose className="w-8 text-white" />
              </div>
            </nav>
            <div className="lg:hidden mobile-menu cursor-pointer" onClick={() => setToggleSidebar(true)}>
              <IconMenu className="w-8" />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
