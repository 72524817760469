import React from 'react'
import PageHeader from '../../components/common/PageHeader'

const Expertise = () => {
  return (
    <section className='welcome-section'>
      <PageHeader title={"Specialize"} subNav={"Expertise"} />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="sm:py-10 py-6 ">
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl font-500 text-center whitespace-nowrap text-gray-700 sm:mb-10 mb-6'>Our Expertise In</h2>
            <div className="detail my-8">
              <div className='flex flex-col max-xsm:text-start max-md:text-sm bg-gray-50 shadow-sm cursor-pointer rounded-md max-w-[992px] mx-auto md:px-20 sm:px-8 px-6 md:py-12'>
                <div className='flex flex-col gap-2.5 border-b-2 last:border-0 border-gray-200 py-6'>
                  <h3 className='md:text-2xl text-lg text-gray-700 font-600'>Power supplies</h3>
                  <h5 className='text-gray-500 font-600'>Our Technical team is expert in Power Electronics as our Engineers are experienced in Component level repairing of Any type Power supply which belongs to Healthcare, Analytical & Industrial Equipment.</h5>
                </div>
                <div className='flex flex-col gap-2.5 border-b-2 last:border-0 border-gray-200 py-6'>
                  <h3 className='md:text-2xl text-lg text-gray-700 font-600'>Motherboards</h3>
                  <h5 className='text-gray-500 font-600'>Our technical team is well-Diverse experienced in repairing of any type of Motherboard on component level.</h5>
                </div>
                <div className='flex flex-col gap-2.5 border-b-2 last:border-0 border-gray-200 py-6'>
                  <h3 className='md:text-2xl text-lg text-gray-700 font-600'>Display Panel/Touch Panel</h3>
                  <h5 className='text-gray-500 font-600'>As we get support from various display vendors from India and Outside the country, we successfully repairs and replace the display panels, Their touch Panels & Driver PCB along With Display Calibration.</h5>
                </div>
                <div className='flex flex-col gap-2.5 border-b-2 last:border-0 border-gray-200 py-6'>
                  <h3 className='md:text-2xl text-lg text-gray-700 font-600'>Single/Multilayer PCB</h3>
                  <h5 className='text-gray-500 font-600'>Our Technical Team and superiors are well aware and well-Experienced to repair single or multilayered PCB's of Any Healthcare, Analytical & Industrial Equipment.</h5>
                </div>
                <div className='flex flex-col gap-2.5 border-b-2 last:border-0 border-gray-200 py-6'>
                  <h3 className='md:text-2xl text-lg text-gray-700 font-600'>Logic PCB's</h3>
                  <h5 className='text-gray-500 font-600'>Other than Power supplies, Motherboards & Display, there are few logic boards in every equipment.Our Team is well experienced to trace the fault in Logic boards and having fault solving abilities.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Expertise
