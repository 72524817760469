import React from 'react'

const IconMail = (props) => {
  return (
    <svg {...props} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.8216 34.1147L35.5452 39.409C33.4055 41.5561 29.6408 41.6024 27.4549 39.409L22.1782 34.1147L3.22705 53.1275C3.93248 53.4537 4.71026 53.6483 5.53713 53.6483H57.4629C58.2898 53.6483 59.0673 53.4539 59.7725 53.1276L40.8216 34.1147Z" fill="currentcolor" />
      <path d="M57.4629 9.35156H5.53711C4.71023 9.35156 3.93246 9.54622 3.22727 9.87242L23.478 30.1903C23.4793 30.1916 23.4809 30.1919 23.4823 30.1932C23.4831 30.1941 23.4837 30.1952 23.4839 30.1964L30.0685 36.8028C30.7679 37.5022 32.2324 37.5022 32.9318 36.8028L39.515 30.1975C39.515 30.1975 39.5166 30.1946 39.518 30.1932C39.518 30.1932 39.5209 30.1916 39.5223 30.1903L59.7725 9.8723C59.0673 9.54598 58.2898 9.35156 57.4629 9.35156ZM0.588902 12.4538C0.223945 13.1919 0 14.0111 0 14.8887V48.1113C0 48.9889 0.223699 49.8081 0.588779 50.5462L19.5728 31.5006L0.588902 12.4538ZM62.4111 12.4536L43.4273 31.5006L62.4111 50.5464C62.7761 49.8084 63 48.9891 63 48.1113V14.8887C63 14.0109 62.7761 13.1916 62.4111 12.4536Z" fill="currentcolor" />
    </svg>

  )
}

export default IconMail
