import React from 'react'
import IconSell from '../../../components/svg/IconSell'
import IconBuy from '../../../components/svg/IconBuy'
import IconPartAndTube from '../../../components/svg/IconPartAndTube'

const WhatWeDo = () => {
  return (
    <section className='what-we-do bg-gray-100'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap mb-4'>What we do</h2>
          <h5 className='xl:text-xl sm:text-base text-xs font-500 text-gray-500 text-center sm:mb-10 mb-6'>( We are successfully repairing : )</h5>
          <div className="grid lg:grid-cols-3 sm:gap-6 gap-4" data-aos="zoom-in" data-aos-duration="2000">
            <div className="card sm:mt-12 w-full relative bg-white rounded-xl sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer" >
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full sm:-mt-[80px]">
                <IconSell className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>HEALTHCARE</h2>
              <div className='max-w-[75%] mx-auto'>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>PATIENT MONITORS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>SYRINGE PUMPS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>CAUTERY</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>VENTILATORS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>ULTRASOUND</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>CR SYSTEM</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>CT/MR</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>WORKSTATIONS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>PRESSURE INJECTORS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'> LAPAROSCOPY CAMERAS</h5>
              </div>
            </div>
            <div className="card sm:mt-12 w-full bg-white rounded-xl sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full sm:-mt-[80px]">
                <IconBuy className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>ANALYTICAL</h2>
              <div className='max-w-[75%] mx-auto'>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>TOC ANALYSERS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>GAS CHROMATOGRAPHY</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>HPLC</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>AIR SAMPLER</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>SHAKERS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>STIRRERS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>INCUBATORS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>BALANCES</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>WATER ANALYZERS</h5>
              </div>
            </div>
            <div className="card sm:mt-12 w-full bg-white rounded-xl sm:p-6 p-4 hover:scale-105 transition-all duration-500 cursor-pointer">
              <div className="p-2.5 bg-gray-100 w-max mx-auto aspect-square rounded-full sm:-mt-[80px]">
                <IconPartAndTube className="lg:w-20 w-16 bg-white rounded-full p-2 shadow-md" />
              </div>
              <h2 className='sm:text-xl text-base text-center font-500 sm:my-4 my-2.5'>INDUSTRIAL</h2>
              <div className='max-w-[75%] mx-auto'>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>SERVO DRIVES</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>DRIVES</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>HMI</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>PLC</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>UPS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>STABILIZERS</h5>
                <h5 className='xl:text-base sm:text-sm text-xs font-500 text-gray-500 text-center mb-1'>SERVO MOTORS</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
