import React from 'react'

const IconLocation = (props) => {
  return (
    <svg {...props} viewBox="0 0 63 63" fill="none">
      <path d="M42.0176 41.6249C48.7215 31.1053 47.8788 32.4177 48.072 32.1434C50.5127 28.7008 51.8027 24.649 51.8027 20.4258C51.8027 9.22606 42.7144 0 31.5 0C20.3222 0 11.1973 9.20784 11.1973 20.4258C11.1973 24.6463 12.5144 28.8042 15.0351 32.2932L20.9822 41.625C14.6238 42.6022 3.81445 45.5141 3.81445 51.9258C3.81445 54.2631 5.33999 57.5939 12.6076 60.1895C17.6823 62.0018 24.3917 63 31.5 63C44.7921 63 59.1855 59.2505 59.1855 51.9258C59.1855 45.5129 48.3889 42.6041 42.0176 41.6249ZM18.1185 30.2628C18.0982 30.2311 18.077 30.2 18.0549 30.1695C15.9573 27.2838 14.8887 23.8638 14.8887 20.4258C14.8887 11.1951 22.3214 3.69141 31.5 3.69141C40.6595 3.69141 48.1113 11.1984 48.1113 20.4258C48.1113 23.8694 47.0628 27.1733 45.0787 29.983C44.9009 30.2175 45.8286 28.7764 31.5 51.26L18.1185 30.2628ZM31.5 59.3086C16.9812 59.3086 7.50586 55.041 7.50586 51.9258C7.50586 49.832 12.3746 46.3893 23.1633 45.0473L29.9435 55.6863C30.1102 55.948 30.3402 56.1635 30.6123 56.3127C30.8843 56.462 31.1896 56.5402 31.4999 56.5402C31.8102 56.5402 32.1155 56.462 32.3875 56.3127C32.6595 56.1635 32.8896 55.948 33.0563 55.6863L39.8363 45.0473C50.6253 46.3893 55.4941 49.832 55.4941 51.9258C55.4941 55.0145 46.1041 59.3086 31.5 59.3086Z" fill="currentcolor" />
      <path d="M31.5 11.1973C26.4114 11.1973 22.2715 15.3372 22.2715 20.4258C22.2715 25.5144 26.4114 29.6543 31.5 29.6543C36.5886 29.6543 40.7285 25.5144 40.7285 20.4258C40.7285 15.3372 36.5886 11.1973 31.5 11.1973ZM31.5 25.9629C28.4468 25.9629 25.9629 23.4789 25.9629 20.4258C25.9629 17.3726 28.4468 14.8887 31.5 14.8887C34.5532 14.8887 37.0371 17.3726 37.0371 20.4258C37.0371 23.4789 34.5532 25.9629 31.5 25.9629Z" fill="currentcolor" />
    </svg>
  )
}

export default IconLocation
