import React, { useState } from 'react'
import PageHeader from '../../components/common/PageHeader'

const WhyConnex = () => {
  return (
    <section className='welcome-section'>
      <PageHeader title={"Why Connex"} subNav={"why connex"} />
      <div className="container">
        <div className='flex flex-col lg:py-16 py-10 md:max-w-[80%] mx-auto'>
          <h1 className='lg:text-4xl md:text-3xl text-2xl text-center text-gray-600 font-600 lg:mb-8 mb-6'>Why Choose Us</h1>
          <div data-aos="fade-right" data-aos-duration="1000">
            <p className='lg:text-lg text-gray-600 font-500 leading-[22px] lg:mb-6 mb-4'>"Connex Provides Aftermarket Services And Solutions That Deliver Value To Our Customers In Supporting Their Sustainability Objectives Towards A Circular Economy.</p>
          </div>
          <div className="grid lg:grid-cols-2 gap-4 items-center">
            <div>
              <h2 className='lg:text-2xl sm:text-2xl text-lg text-gray-700 font-600 mb-2'>Features</h2>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>85% Repair Ratio</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Zero Diagnostic Charge</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>70% Less Charges As Compared To Spares Price</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Less Than 3 Days Turn Around Time.</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Material Pickup Drop Facility</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>State Of Art Repair Facility</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Total Solution</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Work Done By Qualified And Highly Trained Electronics Engineers</h5>
              <h5 className='xl:text-lg text-gray-600 text-start font-500 mb-2'><span className='me-2'>></span>Complete Technical And Customer Support</h5>
            </div>
            <div className="relative">
              <img src={require(`../../assets/images/preventive.jpg`)} className='' alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyConnex
