import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = ({ title, subNav }) => {
  return (
    <div className=" text-center relative max-w-[1640px] mx-auto">
      <div className="md:py-12 py-8 bg-[url('../../../src/assets/images/bg.jpg')] bg-cover bg-[center_top_30rem] bg-blend-darken bg-theme2/20">
        <h1 className='lg:text-4xl md:text-3xl text-2xl text-gray-600 font-600 mb-2.5'>{title}</h1>
        <div className="flex gap-2.5 justify-center max-md:text-sm">
          <h4 className='font-poppins text-lg'><Link to={"/"}>Home</Link></h4>
          <h4 className='font-poppins text-lg'>></h4>
          <h4 className='font-poppins text-lg'>{subNav}</h4>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
