import React from 'react'
import { useLocation } from 'react-router-dom'

const ProductDetail = () => {
  const location = useLocation();
  const productData = location.state;
  return (
    <div className="container">
      <div className="grid lg:grid-cols-2 gap-4 pt-8 justify-center">
        <div className="content text-center rounded-md flex flex-col justify-center sm:px-6 md:max-w-[80%] mx-auto" data-aos="zoom-out" data-aos-duration="1500">
          <h2 className='lg:text-6xl md:text-4xl text-2xl max-lg:text-center text-gray-700 font-600 md:mb-8 mb-4 tracking-wide' >{productData.title}</h2>
          <h5 className='lg:text-2xl md:text-xl md:text-center text-gray-700 font-500 md:mb-8 mb-4'>{productData.data} :</h5>
          <ul className='md:text-xl flex flex-col gap-2'>
            {
              productData?.listData?.map((list) => <li className='text-gray-800 font-600'>{list}</li>)
            }
          </ul>
        </div>
        <div className="border-2 border-gray-200 shadow-lg group flex flex-col justify-center items-center overflow-hidden cursor-pointer" data-aos="zoom-in" data-aos-duration="1500">
          <div className='p-8 aspect-square flex justify-center items-center'>
            <img src={productData.img} className='rounded-md group-hover:scale-110 transition-all duration-500' alt="" />
          </div>
          <div className="content mt-auto text-center md:text-2xl text-xl font-500 text-white group-hover:tracking-wider transition-all duration-500 bottom-0 md:p-6 p-4 bg-black/80 w-full">
            <div className="title">{productData.title}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
