import React from 'react'

const IconSend = (props) => {
  return (
    <svg {...props} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_53230_5936)">
        <path fillRule="evenodd" clipRule="evenodd" d="M32.1911 12.809L17.341 23.1193L1.80843 17.9411C0.724234 17.579 -0.00619845 16.5618 3.96471e-05 15.419C0.00635983 14.2762 0.745164 13.2652 1.83355 12.9157L41.545 0.127162C42.4889 -0.176289 43.525 0.0727426 44.2262 0.773954C44.9273 1.47517 45.1764 2.51118 44.8729 3.45519L32.0844 43.1665C31.7349 44.2549 30.7239 44.9937 29.5811 45C28.4383 45.0063 27.4211 44.2758 27.0589 43.1916L21.8556 27.5838L32.1911 12.809Z" fill="currentcolor" />
      </g>
    </svg>
  )
}

export default IconSend
