import React, { useEffect } from 'react'
import Header from '../common/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../common/Footer'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
      <Header />
      <main className='xl:mt-[74px] mt-[60px]'>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default Layout
