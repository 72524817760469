import React from 'react'
import Welcome from './components/Welcome'
import WhatWeDo from './components/WhatWeDo'
import OurService from './components/OurService'

const Home = () => {
  return (
    <>
      <Welcome />
      <WhatWeDo />
      <OurService />
    </>
  )
}

export default Home
