import React from 'react'
import IconInward from '../../../components/svg/IconInward'
import IconDiagnosisEquipment from '../../../components/svg/IconDiagnosisEquipment'
import IconEstimate from '../../../components/svg/IconEstimate'
import IconRepairProcess from '../../../components/svg/IconRepairProcess'
import IconQcCheck from '../../../components/svg/IconQcCheck'
import IconPackingAndDispatching from '../../../components/svg/IconPackingAndDispatching'

const OurService = () => {
  return (
    <section className='our-service bg-gray-100'>
      <div className="container">
        <div className="lg:py-12 sm:py-8 py-6">
          <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl text-theme font-500 text-center whitespace-nowrap sm:mb-10 mb-6'>Our Repair Process</h2>
          <div className="grid lg:grid-cols-1 lg:gap-6 items-center">
            <div className="services grid md:grid-cols-2 gap-6">
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconInward className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>Inward</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>Equipment received logged in CRM with unique job number for tracking. Acknowledgment email sent to client with equipment details: brand, model, serial number, photos, and job number.
                  </h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconDiagnosisEquipment className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>Diagnosis of Equipment</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>Our Teachnical team allocating the perticular equipment to concern expert Engineer to diagnose the problem.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconEstimate className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>
                    Estimation of Repair Work</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>After Diagnosed of the Equipment, We send the Repair Quotation to Client for approval along with actual TAT for repairs.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconRepairProcess className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>
                    Repair Process</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>After getting approval from Client against quotation, We start Repairing their equipment and make it ready and Send it to QC checks.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconQcCheck className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>
                    QC & Testing Procedures</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>We make sure our repaired equipment is working well and Checked By QC department atleast for 48 Hours in observation.</h5>
                </div>
              </div>
              <div className="flex items-center bg-white shadow-md hover:shadow-lg transition-all cursor-pointer sm:p-4 p-1.5 gap-2 rounded-lg sm:mb-4 mb-2.5" data-aos="fade-right" data-aos-duration="2000" data-delay="300">
                <div className="p-2.5 w-max">
                  <IconPackingAndDispatching className="lg:w-20 w-16 p-2.5 shadow-md" />
                </div>
                <div className="text">
                  <h2 className='md:text-xl sm:text-lg text-sm font-500 mb-1'>
                    Packing & Dispatch</h2>
                  <h5 className='md:text-base sm:text-sm text-xs text-gray-500'>We ensure proper packing with bubble wrap, foam, and Fragile stickers. Delicate items are packed in wood. Dispatched via reputed couriers, train, road, or company vehicle as needed. Dispatch details emailed to clients with tracking.</h5>
                </div>
              </div>
            </div>
            {/* <div className="image max-lg:max-w-[80%] mx-auto lg:col-span-2">
              <Lottie animationData={services} loop={true} />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurService
