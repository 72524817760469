import React from 'react'

const IconVision = (props) => {
  return (
    <svg {...props} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.875 14.0625V16.4062L42.4178 22.6465C41.9841 23.2536 41.4117 23.7485 40.7483 24.0899C40.0848 24.4313 39.3495 24.6094 38.6033 24.6094H37.0078C36.681 25.7069 36.1279 26.7237 35.3842 27.5944C34.6404 28.4651 33.7224 29.1703 32.6895 29.6645L30.4688 36.3281H18.75L16.4063 28.125C15.6442 27.1115 15.2329 25.8774 15.2344 24.6094V22.8354C15.2344 21.5787 15.5448 20.3413 16.1379 19.2333C16.731 18.1253 17.5885 17.1809 18.6343 16.4839L22.2656 14.0625H26.9531L33.4761 16.6714L33.6445 16.2935L35.1563 12.8906H38.6719L38.0244 17.7539L37.8911 18.75H38.6719L44.5313 14.0625H46.875Z" fill="#548AFF" />
      <path d="M18.75 36.3281V53.9062L15.2344 71.4844H18.75L25.7812 57.4219L26.9531 53.9062V36.3281H18.75Z" fill="#415769" />
      <path d="M28.125 8.20312V11.7188C28.125 12.6512 27.7546 13.5454 27.0953 14.2047C26.436 14.864 25.5418 15.2344 24.6094 15.2344C23.677 15.2344 22.7828 14.864 22.1235 14.2047C21.4641 13.5454 21.0938 12.6512 21.0938 11.7188V8.20312H28.125Z" fill="#F5C99D" />
      <path d="M21.0938 8.20312H28.125V6.21416C28.125 5.49846 27.8407 4.81208 27.3346 4.30601C26.8285 3.79993 26.1422 3.51563 25.4265 3.51563C24.8937 3.51562 24.3728 3.67332 23.9295 3.96885L21.0938 5.85938V8.20312Z" fill="#B1712C" />
      <path d="M35.1562 8.20312L28.125 9.375V11.7188L35.1562 12.8906V8.20312Z" fill="#DE9400" />
      <path d="M35.1562 8.20312H42.1875V12.8906H35.1562V8.20312Z" fill="#EDA600" />
      <path d="M42.1875 7.03125H50.3906V14.0625H42.1875V7.03125Z" fill="#FAC623" />
      <path d="M50.3906 5.85938H59.7656V15.2344H50.3906V5.85938Z" fill="#FCD453" />
      <path d="M46.875 14.0625V16.4062L44.4331 19.8237L42.4178 22.646C41.9841 23.2533 41.4117 23.7483 40.7482 24.0898C40.0847 24.4312 39.3493 24.6094 38.603 24.6094H37.0078C37.1309 24.1973 37.221 23.776 37.2773 23.3496L37.8911 18.75H38.6719L41.2764 16.667L44.5312 14.0625H46.875ZM33.4758 16.6715L30.4688 23.4375H24.6094L22.2656 21.0938V19.9219H28.125L30.2667 15.3879L33.4758 16.6715Z" fill="#4976F2" />
      <path d="M16.4058 28.125C16.9511 28.8533 17.6588 29.4443 18.4726 29.8512C19.2864 30.258 20.1839 30.4695 21.0937 30.4688H29.1461C30.3727 30.4699 31.5838 30.1952 32.6898 29.6648L30.4687 36.3281H18.75L16.4058 28.125Z" fill="#486BE0" />
      <path d="M38.6719 12.8906L38.0244 17.7539L33.6445 16.2935L35.1563 12.8906H38.6719ZM46.875 14.0625V16.4062L44.4331 19.8237L41.2764 16.667L44.5313 14.0625H46.875Z" fill="#F5C99D" />
      <path d="M18.75 36.3281V41.0156L26.9531 53.9062L30.4688 71.4844H33.9844V51.5625L30.4688 41.0156V36.3281H18.75Z" fill="#556C80" />
    </svg>

  )
}

export default IconVision
