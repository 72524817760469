import React from 'react'

const IconMenu = (props) => {
  return (
    <svg {...props} viewBox="0 0 54 54" fill="none">
      <path d="M13.5 10.8C13.5 9.30889 14.7089 8.10001 16.2 8.10001H51.3C52.7911 8.10001 54 9.30889 54 10.8C54 12.2911 52.7911 13.5 51.3 13.5H16.2C14.7089 13.5 13.5 12.291 13.5 10.8ZM51.3 24.3H2.7C1.20888 24.3 0 25.509 0 27C0 28.4911 1.20888 29.7 2.7 29.7H51.3C52.7911 29.7 54 28.4911 54 27C54 25.509 52.7911 24.3 51.3 24.3ZM51.3 40.5H27C25.509 40.5 24.3 41.7089 24.3 43.2C24.3 44.691 25.509 45.9 27 45.9H51.3C52.7911 45.9 54 44.691 54 43.2C54 41.7089 52.7911 40.5 51.3 40.5Z" fill="currentcolor" />
    </svg>

  )
}

export default IconMenu
