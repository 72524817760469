import React from 'react'
import PageHeader from '../../components/common/PageHeader'
import img1 from "../../assets/images/TOC_ANALYSER.png"
import img2 from "../../assets/images/GAS_CHROMATOGRAPHY.png"
import img3 from "../../assets/images/HPLC.png"
import img4 from "../../assets/images/AIR_SAMPLER.png"
import img5 from "../../assets/images/SHAKERS.png"
import img6 from "../../assets/images/STIRRER.png"
import img7 from "../../assets/images/INCUBATOR.png"
import img8 from "../../assets/images/BALANCE.png"

const datas = [
  { title: "TOC analyser", detail: "Successfully repairing Sievers TOC analyser such as 500RL , 900RL & Checkpoint", img: img1 },
  { title: "GAS Chromatography", detail: "Successfully repairing G.C of Agilent, Shimadzu, Thermofisher brands and their models.", img: img2 },
  { title: "HPLC", detail: " Successfully repairing HPLC of Agilent, Shimadzu, Waters Etc. & All their models.", img: img3 },
  { title: "Air Sampler", detail: "Successfully repairing all brands of Air samplers with all models.", img: img4 },
  { title: "Shakers", detail: "Successfully repairing All brands and Chinese models of Air samplers", img: img5 },
  { title: "Stirrers", detail: "Successfully repairing IKA, Remi and other brands & their models.", img: img6 },
  { title: "Incubators", detail: "Successfully repairing Thermofisher and other All brands of Incubators.", img: img7 },
  { title: "Balance", detail: "Successfully repairing Shimadzu, Sartorious, Cole-Parmar Etc. & their All models.", img: img8 }
]

const Analytical = () => {
  return (
    <section className='welcome-section'>
      <PageHeader title={"Products"} subNav={"Analytical"} />
      <div className='bg-gray-100'>
        <div className="container">
          <div className="sm:py-10 py-6">
            <h2 className='lg:text-4xl sm:text-3xl xsm:text-2xl text-xl font-500 text-center whitespace-nowrap text-gray-700 sm:mb-10 mb-6' >Analytical</h2>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4 justify-center">
              {
                datas.map((data) =>
                  <div className='max-md:text-center max-xsm:text-start bg-gray-50 shadow-sm cursor-pointer p-4 rounded-md' data-aos="zoom-out" data-aos-duration="1500">
                    <div className='h-full pt-4 overflow-hidden'>
                      <h2 className='sm:text-2xl text-xl text-center text-gray-600 font-500 mb-2'>{data.title}</h2>
                      <h2 className='text-center sm:text-base text-sm text-gray-600 font-600 mb-6'>{data.detail}</h2>
                      <div className='bg-gray-100 p-4 md:aspect-square'>
                        <img className='w-full h-full max-h-[400px] object-contain' src={data.img} alt="" />
                      </div>
                    </div>
                  </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Analytical
