import React from 'react'
import PageHeader from '../../components/common/PageHeader'
import ProductDetail from './ProductDetail'

const Product = () => {
  return (
    <section className='welcome-section'>
      <PageHeader title={"Products"} subNav={"Healthcare"} />
      <div className="lg:py-20 py-12 bg-gray-100">
        <ProductDetail />
      </div>
    </section>
  )
}

export default Product
