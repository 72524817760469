import React from 'react'
import video1 from "../../assets/videos/video1.mp4"
import video2 from "../../assets/videos/video2.mp4"

const CorporateVideos = () => {
  return (
    <section className="corporate-videos">
      <div className="container lg:py-20 py-12">
        <div className="grid lg:grid-cols-2 gap-8">
          <div className="video">
            <video className='aspect-[12/10]' src={video1} controls></video>
          </div>
          <div className="video">
            <video className='aspect-[12/10]' src={video2} controls></video>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CorporateVideos
